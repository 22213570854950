
























import axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Newsletter extends Vue {
  private email = "";
  private hideForm = false;
  private disableForm = false;

  handleSignup(): void {
    this.disableForm = true;
    const mailerUrl = "/cgi-bin/form.php";
    const payload: { [index: string]: any } = {
      email: this.email,
      message: "Let me know when the beta is done",
      origin: "metrify.co website",
      "info-anfordern-botcheck": "",
      subject: "[metrify HP] Infos zum Beta erwuenscht",
      html_title: "[metrify HP] Infos zum Beta erwuenscht",
      prefix: "info-anfordern-",
    };
    const data = new FormData();
    for (let key of Object.keys(payload)) {
      data.append(key, payload[key]);
    }
    axios
      .post(mailerUrl, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response);
        if (response.data?.alert === "success") {
          this.success();
          this.hideForm = true;
        } else {
          this.fail("Something went wrong … please try again later. Thanks!");
          this.disableForm = false;
        }
      })
      .catch((e) => {
        this.fail("A network error occurred. Please try again later. Thanks!");
        this.disableForm = false;
      });
  }

  success() {
    this.$buefy.toast.open({
      message: "Thank you for your interest. We'll be in touch!",
      type: "is-success",
    });
  }

  fail(msg: string) {
    this.$buefy.toast.open({
      duration: 5000,
      message: msg || `Something went wrong`,
      position: "is-bottom",
      type: "is-danger",
    });
  }
}
