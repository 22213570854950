<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <div class="container">
      <router-view />
    </div>
    <footer class="footer">
      <Footer year="2021" />
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/PageFooter";

@Component({
  components: {
    Footer,
    Navbar,
  },
})
export default class App extends Vue {}
</script>

<style></style>
