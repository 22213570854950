<template>
  <section id="twitter-feed" class="section has-white-company-gradient">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="box">
            <article class="media">
              <div class="media-left">
                <figure class="image is-64x64">
                  <img src="" alt="Image" />
                </figure>
              </div>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Happy Customer #1</strong> <small>TU München</small>
                    <br />
                    Testimonial 1
                  </p>
                </div>
                <nav class="level is-mobile">
                  <div class="level-left">
                    <a class="level-item" aria-label="reply">
                      <b-icon pack="fas" icon="reply" size="is-small"> </b-icon>
                    </a>
                    <a class="level-item" aria-label="retweet">
                      <b-icon pack="fas" icon="retweet" size="is-small">
                      </b-icon>
                    </a>
                    <a class="level-item" aria-label="like">
                      <b-icon pack="fas" icon="heart" size="is-small"> </b-icon>
                    </a>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        </div>
        <div class="column">
          <div class="box">
            <article class="media">
              <div class="media-left">
                <figure class="image is-64x64">
                  <img src="" alt="Image" />
                </figure>
              </div>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Happy Customer #2</strong> <small>Sozialträger</small>
                    <br />
                    Testimonial 2
                  </p>
                </div>
                <nav class="level is-mobile">
                  <div class="level-left">
                    <a class="level-item" aria-label="reply">
                      <b-icon pack="fas" icon="reply" size="is-small"> </b-icon>
                    </a>
                    <a class="level-item" aria-label="retweet">
                      <b-icon pack="fas" icon="retweet" size="is-small">
                      </b-icon>
                    </a>
                    <a class="level-item" aria-label="like">
                      <b-icon pack="fas" icon="heart" size="is-small"> </b-icon>
                    </a>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Testimonials",
};
</script>

<style scoped></style>
