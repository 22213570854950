import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Plugins:
import Buefy from "buefy";
import VueI18n from "vue-i18n";

// Stylesheets
import "./assets/scss/app.scss";

Vue.use(VueI18n);
Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
