<template>
  <section id="features" class="section has-company-bg">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column">
          <h3 class="is-size-3 is-size-4-mobile">Complex study design</h3>
          <p class="has-text-white paragraph">
            Complex study designs are essential in modern survey research.
            Metrify helps you deal with that complexity by offering an
            all-in-one platform. Starting from selecting valid and reliable
            measurement scales, setting up complex cross-sectional or
            longitudinal survey designs to data analysis and reporting.
          </p>
        </div>
        <div class="column">
          <img class="feature-img" src="../../../public/img/data-view.png" />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="columns is-flex-direction-row-reverse is-vcentered">
        <div class="column">
          <h3 class="is-size-3 is-size-4-mobile">
            Longitudinal Studies and Participant Management
          </h3>
          <p class="has-text-white paragraph">
            Participant and cohort management are becoming more and more
            important for high quality survey research. Yet setting up panels,
            managing participation, and sending invites and reminders is a chore
            in many other tools. We offer an integrated experience for
            researchers and participants alike, taking survey projects to the
            next level with ease.
          </p>
        </div>
        <div class="column">
          <img
            class="feature-img"
            src="../../../public/img/group.png"
            height="800"
            width="800"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="columns is-vcentered">
        <div class="column">
          <h3 class="is-size-3 is-size-4-mobile">Integrated Reporting</h3>
          <p class="has-text-white paragraph">
            Reporting is a central part of every survey project. Our philosophy
            is to have one unified environment for the complete research-project
            lifecycle. That is why reports can be generated quickly from survey
            results, simply by clicking a button.
          </p>
        </div>
        <div class="column">
          <img class="feature-img" src="../../../public/img/collab.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Features",
};
</script>

<style scoped>
p {
  font-size: 1.25rem;
  color: white;
}
h3 {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
</style>
