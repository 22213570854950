














































import { Component, Vue } from "vue-property-decorator";
import Testimonials from "@/components/Home/Testimonials.vue";
import Features from "@/components/Home/Features.vue";
import Newsletter from "@/components/Home/Newsletter.vue"; // @ is an alias to /src

@Component({
  components: {
    Newsletter,
    Features,
    Testimonials,
  },
})
export default class Home extends Vue {}
